.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a.Title {
  padding-bottom: 2.5%;
  padding-top: 2.5%;
  margin-top: 20vh;
  margin-bottom: 80px;
  width: 60vw;
  vertical-align: middle;
  border-width: 10px;
  border-style: solid;
  border-color: #f0ead6;
  border-radius: 11px;
  font-family: 'Roboto', 'Nunito', 'Open Sans', sans-serif;
  text-align: center;
  font-size: 6vw;
  color: #f0ead6;
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: 700; 
  word-wrap: break-word;
  transition: 0.2s;
  overflow-y: auto;
  
}

a.Title:hover {
  border-style: solid;
  border-color: #FFB238;
  background-color: #444952;
  transition: 0.2s;
  color: white;
}



a.Credits {
  font-family: 'Nunito', 'Open Sans', sans-serif;
  font-size: 15px;
  color: grey;
  text-decoration: none;
  position: absolute;
  left: 15px;
  bottom: 10px;
}


h1 {
  font-family: 'Nunito', 'Open Sans', sans-serif;
  text-align: center;
  font-size: 6vw;
  color: white;
  text-decoration: none;


}

img.Logo {
  margin-bottom: 1vh;
  position: absolute;
  left: 20px;
  top: 20px;
  width: 25vh;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  a.Title {
    border-width: 4px;
    margin-top: 10vh;
    margin-bottom: 10vh;
    width: 80vw;
  }
}
